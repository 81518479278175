var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card-container"},[_c('div',{staticClass:"row-div"},[_c('div',{staticClass:"row-info-div"},[_c('div',{staticClass:"title-div",class:{
            'primary-title': _vm.variant_primary,
            'secondary-title': _vm.variant_secondary,
            'thirth-title': _vm.variant_thirth,
            'fourth-title': _vm.variant_fourth,
            'fifth-title': _vm.variant_fifth,
            'sixth-title': _vm.variant_sixth,
            'seventh-title': _vm.variant_seventh,
            'eighth-title': _vm.variant_eighth,
            'nineth-title': _vm.variant_nineth,
            'tenth-title': _vm.variant_tenth,
          }},[_vm._t("title")],2)])]),_c('div',{staticClass:"card-body",class:{
        'primary-body': _vm.variant_primary,
        'secondary-body': _vm.variant_secondary,
        'thirth-body': _vm.variant_thirth,
        'fourth-body': _vm.variant_fourth,
        'fifth-body': _vm.variant_fifth,
        'sixth-body': _vm.variant_sixth,
        'seventh-body': _vm.variant_seventh,
        'eighth-body': _vm.variant_eighth,
        'nineth-body': _vm.variant_nineth,
        'tenth-body': _vm.variant_tenth,
      }},[_vm._t("content")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }